import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "lib-flexible";
import { Button,Icon,Tab, Tabs,Collapse,CollapseItem,Popup,Swipe,SwipeItem,Dialog,Search,Form, Field, CellGroup,RadioGroup, Radio,Picker,Toast,DatetimePicker,Checkbox,List,Divider,PullRefresh,Calendar} from "vant";

Vue.use(Button).use(Icon).use(Tab).use(Tabs).use(Collapse).use(CollapseItem).use(Swipe).use(SwipeItem).use(Dialog).use(Search).use(Form).use(Field).use(CellGroup).use(Toast);
Vue.use(Popup)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Picker)
Vue.use(DatetimePicker)
Vue.use(Checkbox)
Vue.use(Divider)
Vue.use(PullRefresh)
Vue.use(List)
Vue.use(Calendar)
Vue.config.productionTip = false;
//19环境
// Vue.prototype.pdfurls = 'https://mtwedu.maitewang.com/file/'
//线上环境
Vue.prototype.pdfurls = 'https://cms.mtwedu.com/file/'

import service from "./utils/request";
Vue.prototype.$axios = service;
import "video.js/dist/video-js.css";
import VideoPlayer from "vue-video-player/src";
Vue.use(VideoPlayer);
import headCom from '@/components/headCom'
Vue.component('headCom',headCom)
import list from '@/components/list'
Vue.component('list',list)
import fixed from "@/components/fixed.vue"
Vue.component('fixed',fixed)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
