import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";
import {getToken} from '../utils/auth'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: getToken(),
    userInfo: {},
  },
  getters: {},
  mutations: {
    setToken(state, data) {
      state.token = data.access_token;
      state.userInfo = data.info
    },
    exit(state){
      state.userInfo = {}
      state.token = ''
    }
  },
  actions: {},
  modules: {},
  plugins: [persistedState()],
});
