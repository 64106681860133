<template>
  <div>
    <div class="flex nav plr-20">
      <div style="width: 30px">
        <van-icon
          v-if="!$route.meta.isHide"
          name="arrow-left"
          class="size-18"
          @click="handleLast"
        />
      </div>
      <div class="size-18">{{ $route.meta.title }}</div>
      <div v-if="!token" class="size-16" @click="showLogin">登录</div>
      <div v-else class="size-16 hover" @click="showLoginOut = !showLoginOut">
        <span>{{ info.name }}</span>

        <div :class="showLoginOut ? 'showOut' : 'showOut_1'">
          <div class="triangle"></div>
          <div
            class="center pl-10 pr-14 mb-15 pt-5"
            @click="$router.push('ScoreQuery')"
          >
            <img class="mr-5" src="../assets/grades.png" alt="" />
            <span>成绩查询</span>
          </div>
          <div
            class="center pl-10 pr-14 mb-15"
            @click="$router.push('academic')"
          >
            <img class="mr-5" src="../assets/academic.png" alt="" />
            <span>教务信息</span>
          </div>
          <div class="center pl-10 pr-14 mb-10" @click="loginOut">
            <img class="mr-5" src="../assets/login_out.png" alt="" />
            <span>退出登录</span>
          </div>
        </div>
      </div>
      <van-popup v-model="show" class="radius-20">
        <div class="pd-10">
          <div class="flex_r">
            <van-icon name="cross" size="13px" @click="show = false" />
          </div>
          <div class="text-center size-18 mb-10">密码登录</div>
          <van-search
            placeholder="请输入账号"
            v-model="username"
            shape="round"
            class="username"
            ><van-icon slot="left-icon" name="manager-o" color="#cccccc"
          /></van-search>
          <van-search
            placeholder="请输入密码"
            v-model="password"
            shape="round"
            type="password"
            class="username"
            ><van-icon slot="left-icon" name="bag-o" color="#cccccc"
          /></van-search>
          <div class="flex">
            <van-search
              placeholder="验证码"
              v-model="code"
              shape="round"
              class="code"
              ><van-icon slot="left-icon" name="coupon-o" color="#cccccc"
            /></van-search>
            <img @click="getCode" :src="codeImg" class="code pr-15" alt="" />
          </div>
          <van-button type="info" class="username" round @click="handleLogin"
            >登 录</van-button
          >
          <div class="flex_r">
            <van-checkbox v-model="checked" shape="square"
              >记住密码</van-checkbox
            >
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie"
import { encrypt, decrypt } from "../utils/jsencrypt"
import { getToken, setToken, removeToken } from "../utils/auth"
export default {
  props: [""],
  data() {
    return {
      show: false, // 登录弹层
      username: "", // 账号
      password: "", // 密码
      code: "", // 验证码
      checked: false, // 是否记住密码
      codeImg: "", //验证图片
      uuid: "", // 验证码接口参数
      info: this.$store.state.userInfo ? this.$store.state.userInfo : {}, // 用户信息
      token: this.$store.state.token,
      showLoginOut: false,
    }
  },
  mounted() {},
  created() {
    this.getCookie()
  },
  methods: {
    // 获取验证码
    getCode() {
      this.$axios.get("/stage-api/code").then((res) => {
        this.codeImg = "data:image/gif;base64," + res.img
        this.uuid = res.uuid
      })
    },
    // 返回上一级
    handleLast() {
      this.$router.go(-1)
    },
    // 打开登录
    showLogin() {
      this.show = true
      this.getCode()
    },
    // 获取
    getCookie() {
      const username = Cookies.get("username")
      const password = Cookies.get("password")
      const checked = Cookies.get("checked")
      this.username = username === undefined ? this.username : username
      this.password = password === undefined ? this.password : decrypt(password)
      this.checked = checked === undefined ? false : Boolean(checked)
    },
    // 登录
    handleLogin() {
      if (!this.username) {
        this.$toast("请输入账户")
        return
      }
      if (!this.password) {
        this.$toast("请输入密码")
        return
      }
      if (!this.code) {
        this.$toast("请输入验证码")
        return
      }
      this.$axios
        .post("/stage-api/auth/studentLogin", {
          username: this.username,
          password: this.password,
          code: this.code,
          uuid: this.uuid,
        })
        .then((res) => {
          if(res.code==500){
            this.getCode()
          }
          this.$store.commit("setToken", res.data)
          setToken(res.data.access_token)
          this.token = getToken()
          this.info = res.data.info
          if (this.checked) {
            Cookies.set("username", this.username, { expires: 7 })
            Cookies.set("password", encrypt(this.password), {
              expires: 7,
            })
            Cookies.set("checked", this.checked, {
              expires: 7,
            })
          } else {
            Cookies.remove("username")
            Cookies.remove("password")
            Cookies.remove("checked")
          }
          this.show = false
        }
      )
    },
    //退出登录
    loginOut() {
      this.$store.commit("exit")
      removeToken()
      location.href = "/"
    },
  },
}
</script>
<style lang="scss" scoped>
.nav {
  height: 44px;
  background-color: white;
}
img {
  width: 0.5rem;
  height: 0.5rem;
}
.username {
  width: 7rem;
  height: 1rem;
  margin-bottom: 10px;
}
.code {
  width: 3.5rem;
  height: 1rem;
  margin-bottom: 10px;
}
.hover {
  position: relative;
  z-index: 99999999999;
  .showOut_1 {
    display: none;
  }
}
.hover {
  .showOut {
    display: block;
    position: absolute;
    top: 20px;
    right: -10px;
    margin-top: 5px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 14px;
    width: 110px;
    border-radius: 5px;
    .triangle {
      position: relative;
      top: -7px;
      left: 70px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px dashed rgba(0, 0, 0, 0.45);
    }
  }
}
</style>
