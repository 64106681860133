<template>
  <div id="detail">
    <headCom></headCom>
    <fixed />
    <img src="../../assets/home/home_1.png" class="w100" />
    <div class="m-1">
      <div class="flex ptb-24 plr-26">
        <div @click="handleCenter">
          <img src="../../assets/home/home_center.png" />
          <div>中心介绍</div>
        </div>
        <div @click="handleRecruit">
          <img src="../../assets/home/home_enrollment.png" />
          <div>招生</div>
        </div>
        <div @click="handleCourse">
          <img src="../../assets/home/home_course.png" />
          <div>课程中心</div>
        </div>
      </div>
      <div class="flex_l plr-26">
        <div @click="handleStyle" class="mr-55">
          <img src="../../assets/home/home_student.png" alt="" />
          <div>学生风采</div>
        </div>
        <!-- <div>
          <img src="../../assets/home/home_company.png" alt="" />
          <div>名企招聘</div>
        </div> -->
        <div @click="handleScore">
          <img src="../../assets/home/home_grad.png" alt="" />
          <div>成绩查询</div>
        </div>
      </div>
    </div>
    <div class="bg mt-16"></div>
    <div id="bgcolor" class="mt-9 mb-40 pd-16">
      <div class="flex mb-10">
        <div class="size-16">
          <div class="w3 bc-blue"></div>
          <p class="title relative pl-20 line-4">新闻中心</p>
        </div>
        <div
          v-if="allPage1 > 1"
          class="flex_r"
          style="height: 35px"
          @click="refresh(1)"
        >
          <img src="~@/assets/home/index-refresh.png" alt="" />
        </div>
      </div>

      <div
        class="backgroundclass"
        v-for="(item, index) in topList"
        :key="index"
      >
        <div @click="handleDetail(item.id)">
          <div class="flex1 flex-left mb-16">
            <div class="mr-20 size-16">
              <img
                style="width: 12px; height: 12px"
                src="~@/assets/up.svg"
                alt=""
              />
            </div>

            <div class="size-16 line-2">{{ item.theme }}</div>
          </div>

          <p class="size-14 gray-3">{{ item.creatTime }}</p>
        </div>
      </div>
      <!-- 新闻列表 -->
      <div class="mt-15" v-if="newsList.length > 0">
        <div class="flex">
          <div class="size-16"></div>
          <div
            v-if="allPage2 > 1"
            class="flex_r"
            style="height: 35px"
            @click="refresh(2)"
          >
            <img src="~@/assets/home/index-refresh.png" alt="" />
          </div>
        </div>
        <div
          class="bb ptb-20 point"
          @click="handleDetail(item.id)"
          v-for="(item, i) in newsList"
          :key="i"
        >
          <div class="size-16 pl-16 pr-16">
            <p class="line-2 mb-15">
              {{ item.theme }}
            </p>
            <p class="size-14 gray-3">{{ item.creatTime }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bg mt-16"></div>
    <div class="friends w100">
      <div class="size-16 mb-19 black">
        <div class="w3 bc-blue"></div>
        <p class="title relative pl-20 line-4">合作伙伴</p>
      </div>
      <div class="firend-img"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      topList: [], // 置顶新闻列表
      pageSize: 5,
      page2: 1,
      page1: 1,
      newList: [], // 新闻列表
      total: "", // 新闻列表总条数
      loading: false,
      finished: false,
      newsList: [],
      allPage1: "",
      allPage2: "",
    }
  },
  created() {},

  mounted() {
    this.getTopNewList()
    this.getNewsList()
  },
  methods: {
    // 跳转到课程
    handleCourse() {
      this.$router.push("/course")
    },
    // 跳转到学生风采
    handleStyle() {
      this.$router.push("/studentStyle")
    },
    // 跳转到中心介绍
    handleCenter() {
      this.$router.push("/introductionCenter")
    },
    // 跳转到招生入口
    handleRecruit() {
      this.$router.push("/recruit")
    },
    handleDetail(id) {
      const title = "新闻中心"
      this.$router.push({
        path: "listDetail",
        query: {
          id,
          title,
        },
      })
    },
    // 获取置顶新闻列表
    async getTopNewList() {
      const { rows, total } = await this.$axios.get(
        "/stage-api/info/webNews/topList?pageSize=4&pageNum=" + this.page1
      )
      this.topList = rows
      this.allPage1 = total % 4 == 0 ? total / 4 : parseInt(total / 4) + 1
    },
    async getNewsList() {
      const { rows, total } = await this.$axios.get(
        "/stage-api/info/webNews/list?pageSize=5&pageNum=" + this.page2
      )
      this.newsList = rows
      this.allPage2 = total % 5 == 0 ? total / 5 : parseInt(total / 5) + 1
    },
    // 换一换
    refresh(type) {
      if (type == 1) {
        if (this.page1 == this.allPage1) {
          this.page1 = 1
        } else {
          this.page1++
        }
        this.getTopNewList()
      } else if (type == 2) {
        if (this.page2 == this.allPage2) {
          this.page2 = 1
        } else {
          this.page2++
        }
        this.getNewsList()
      }
    },

    // 跳转到成绩查询
    handleScore() {
      if (this.$store.state.token == "" || this.$store.state.token == undefined) {
        this.$toast.fail("请先登录！")
      } else {
        this.$router.push("/ScoreQuery")
      }
    },
  },

  components: {},
}
</script>
<style lang="scss" scoped>
.m-1 {
  position: relative;
  z-index: 2;
  background-color: white;
  margin-top: -20px;
  border-radius: 15px 15px 0px 0px;
}
#detail .title::before {
  position: absolute;
  content: "";
  display: block;
  width: 6px;
  height: 26px;
  left: 0px;
  top: 50%;
  margin-top: -12px;
  background: #2856f7;
}
#bgcolor .title1::before {
}
.flex > div {
  width: 25%;
  text-align: center;
  div {
    font-size: 14px;
  }
}
.bg {
  width: 375px;
  height: 10px;
  background: rgba(248, 248, 248, 1);
}
.friends {
  padding: 20px;
}
.firend-img {
  background: url("~@/assets/home/friends.png");
  background-size: cover;
  width: 335px;
  height: 337px;
}
.flex_l > div {
  width: 22%;
  text-align: center;
  div {
    font-size: 14px;
  }
}
.backgroundclass {
  padding: 16px;
  margin-bottom: 10px;
  border: 0.5px solid #eee;
  box-shadow: 1px 1px 1.5px #bfc2c4;
}
</style>
