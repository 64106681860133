<template>
  <div>
    <div class="flex_l">
      <div class="size-16 mr-5 pl-20 bold">{{ title }}</div>
    </div>
    <div
      class=" bb pb-16 mt-16 mlr-20"
      v-for="item in topList"
      :key="item.id"
    >
      <div @click="handleDetail(title, item.id)" class="flex_l">
        <div class="mr-20 size-16">
          <img
            style="width: 12px; height: 12px"
            src="../assets/up.svg"
            alt=""
          />
        </div>
        <div>
          <div class="size-16 mb-16">{{ item.theme }}</div>
          <div class="size-14 gray-3">{{ item.creatTime }}</div>
        </div>
      </div>
    </div>
    <div
      class="bb pb-10 mt-14 mlr-20"
      v-for="(item, index) in list"
      :key="item.id"
    >
      <div v-if="item.sort == 1" @click="handleDetail(title, item.id)">
        <div class="flex_l mb-10">
          <div class="mr-20 size-16">
            <img
              style="width: 12px; height: 12px"
              src="../assets/up.svg"
              alt=""
            />
          </div>
          <div class="size-16">{{ item.theme }}</div>
        </div>
        <div class="size-14 gray-3 ml-30">{{ item.creatTime }}</div>
      </div>
      <div v-if="item.sort == 0" @click="handleDetail(title, item.id)">
        <div class="flex_l mb-10">
          <div class="mr-20 size-16">{{ index + 1 }}</div>
          <div class="size-16">{{ item.theme }}</div>
        </div>
        <div class="size-14 gray-3 ml-30">{{ item.creatTime }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    topList: Array,
    list: Array,
    title: String, //标题
  },
  data() {
    return {}
  },
  components: {},
  created() {},
  mounted() {},
  methods: {
    handleDetail(title, id) {
      this.$router.push({
        path: "listDetail",
        query: {
          id,
          title,
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
img {
  width: 35%;
  height: 35%;
}
</style>
