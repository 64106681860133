<template>
  <div class="fixed">
    <!-- <div class="shadow bg-white center ptb-5 border-radius">
      <img src="../assets/fixed/fixed_1.png" alt="" />
    </div>
    <div @click="handleRecruit" class="shadow center bg-color ptb-5 mtb-10 border-radius">
      <img src="../assets/fixed/fixed_2.png" alt="" />
    </div> -->
    <div @click="goTop" class="shadow bg-white center ptb-5 border-radius">
      <img src="../assets/fixed/fixed_3.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollTop: null,
    }
  },
  components: {},
  created() {},
  mounted() {},
  methods: {
    goTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 20)
    },
    // 跳转到招生入口
    handleRecruit(){
      this.$router.push('/recruitStudents')
    },
  },
}
</script>

<style scoped lang="scss">
.fixed {
  position: fixed;
  z-index: 999999;
  top: 600px;
  right: 0;
}
img {
  width: 40%;
  margin-left: 5px;
}
.border-radius {
  border-radius: 15px 0px 0px 15px;
}
.bg-color {
  background-color: #2856f7;
}
.shadow {
  height: 35px;
  box-shadow: -1px 2px 5px 2px #d4dee7;
}
</style>
