import Vue from "vue"
import VueRouter from "vue-router"
import home from "../views/home/index.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: home,
    meta: {
      isHide: true, //顶部是否返回，默认false
      title: "首页",
    },
  },
  {
    path: "/course",
    name: "course",
    component: () => import("../views/course/index.vue"),
    meta: {
      isHide: false, //顶部是否返回，默认false
      title: "课程中心",
    },
  },
  {
    path: "/detail",
    name: "detail",
    component: () => import("../views/course/detail.vue"),
    meta: {
      isHide: false, //顶部是否返回，默认false
      title: "课程中心",
    },
  },
  {
    path: "/video",
    name: "video",
    component: () => import("../views/course/video.vue"),
    meta: {
      isHide: false, //顶部是否返回，默认false
      title: "课程中心",
    },
  },
  {
    path: "/studentStyle",
    name: "course",
    component: () => import("../views/studentStyle/index.vue"),
    meta: {
      isHide: false, //顶部是否返回，默认false
      title: "学生风采",
    },
  },
  {
    path: "/introductionCenter",
    name: "course",
    component: () => import("../views/introductionCenter/index.vue"),
    meta: {
      isHide: false, //顶部是否返回，默认false
      title: "中心介绍",
    },
  },
  {
    path: "/recruitStudents",
    name: "recruitStudents",
    component: () => import("../views/recruitStudents/index.vue"),
    meta: {
      isHide: false, //顶部是否返回，默认false
      title: "招生入口",
    },
  },
  {
    path: "/ScoreQuery",
    name: "ScoreQuery",
    component: () => import("../views/ScoreQuery/index.vue"),
    meta: {
      isHide: false, //顶部是否返回，默认false
      title: "成绩列表",
    },
  },
  {
    // 招生--周瀛
    path: "/recruit",
    name: "recruit",
    component: () => import("../views/recruit/index.vue"),
    meta: {
      isHide: false, //顶部是否返回，默认false
      title: "招生",
    },
  },
  {
    // 教务信息-周瀛
    path: "/academic",
    name: "academic",
    component: () => import("../views/academic/academic.vue"),
    meta: {
      isHide: false, //顶部是否返回，默认false
      title: "教务信息",
    },
  },
  {
    // 详情-周瀛
    path: "/listDetail",
    name: "listDetail",
    component: () => import("../views/detail/detail.vue"),
    meta: {
      isHide: false, //顶部是否返回，默认false
      title: "详情",
    },
  },
]

const router = new VueRouter({
  mode: "history",
  routes,
})

export default router
